/** 
 * import : 
 * 
 * @version 0.1 (2021.05.27 : usayama_dx)
*/
import React from 'react'
import PropTypes from "prop-types"
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
// conf, action
// components
// helper
// style, img
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
require('@scss/plugins/_react-bootstrap-table2.css')
// consts

/** 
 * const : 
 * 
 * @version 0.1 (2021.05.27 : usayama_dx)
*/
const ReactBootstrapTable = props => {
  /** 
   * const : 
   * 
   * @version 0.1 (2021.05.27 : usayama_dx)
   * @version 0.2 (2021.06.19 : usayama_dx) // sort, defaultSorted 追加
   */
  const { keyField, data, columns, sort, defaultSorted,...attrs } = props
  const pagenationOption = {
    paginationSize: 3,
    sizePerPageList:[
      { text: '15', value: 15 }, 
      { text: '25', value: 25 }, 
      { text: '50', value: 50 }, 
      { text: 'All', value: data.length}
    ]
  }

  /** 
   * return : 
   * 
     * @version 0.1 (2021.05.27 : usayama_dx)
     * @version 0.2 (2021.06.19 : usayama_dx) // sort 追加
   */
  return (
    <>
    {
      data && 
        <BootstrapTable 
          keyField={keyField}
          data={data}
          columns={columns}
          striped
          bordered={false}
          noDataIndication="Table is Empty"
          defaultSorted={[{ dataField : defaultSorted ? defaultSorted : keyField, order : sort }]}
          pagination={ paginationFactory(pagenationOption) }
          wrapperClasses="table-responsive"
          {...attrs}
        />
    }
    </>
  )

}

/** 
 * propTypes
 * 
 * @version 0.1 (2021.05.27 : usayama_dx)
 * @version 0.2 (2021.06.19 : usayama_dx) // sort, defaultSorted 追加
*/
ReactBootstrapTable.propTypes = {
  keyField: PropTypes.string,
  defaultSorted: PropTypes.string,
  data: PropTypes.array,
  columns: PropTypes.array,
  sort: PropTypes.string,
}

/** 
 * defaultProps
 * 
 * @version 0.1 (2021.05.27 : usayama_dx)
 * @version 0.2 (2021.06.19 : usayama_dx) // sort , defaultSorted追加
*/
ReactBootstrapTable.defaultProps = {
  keyField: "",
  defaultSorted: "",
  data: [],
  columns: [],
  sort: 'desc',
}

/** 
 * export
 * 
 * @version 0.1 (2021.05.27 : usayama_dx)
*/
export default ReactBootstrapTable
