/** 
 * import : 
 * 
 * @version 0.1 (2021.05.17 : usayama_dx)
*/
import React from 'react'
import classNames from "classnames"
// conf, action
// components
import Label from '@components/Typo/Label'
import LabelHtml from '@components/Typo/LabelHtml'
// containers
// helper
// style, img
// consts

/** 
 * const : 
 * 
 * @version 0.1 (2021.05.17 : usayama_dx)
*/
export default function AuthContainer(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.05.17 : usayama_dx)
   * @version 0.2 (2021.06.30 : usayama_dx) // className 削除
   */
  const { titleLabel,
          textLabel,
          children, 
          ...attrs } = props
  const classes = classNames(
    "container",
    "wrap",
    "auth",
  )

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.17 : usayama_dx)
   * @version 0.2 (2021.06.30 : usayama) // className= に修正
   */
  return (
      <div className={classes} {...attrs}>
        <div className="mt-2 mb-4 text-center">
            <h2 className="h2 font-weight-bold">
                <Label label={titleLabel} />
            </h2>
        </div>
        <div className="container">
          <div className="card">
            <div className="card-body p-4">
              { textLabel && <p><LabelHtml label={textLabel} /></p> }
              { children }
            </div>
          </div>
        </div>
      </div>
  )
}
