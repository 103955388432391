/**
 * import
 *
 * @version 0.1 (2021.06.21 : usayama)
 * @version 0.2 (2021.07.26 : usayama) // APP_ROUTE 追加
 */
import React from 'react'
// conf, action
import { APP_ROUTE } from '@config/routers'
// components'
import Label from '@components/Typo/Label'
import LabelHtml from '@components/Typo/LabelHtml'
// helper
// const
const moment = require('moment')
let numeral = require('numeral')

/** 
 * TABLE
 * 
 * @version 0.1 (2021.06.21 : usayama)
*/
export const BOOTSTRAP_TABLE_COLUMNS_POINTS = [
  { 
    headerFormatter: () => <Label label={"label.points.created_at"} />,
    text: 'created_at', 
    dataField: 'created_at', 
    sort: true,
    formatter: (cell, row) => <div>
      <small>{moment(row.created_at).format('YYYY-MM-DD')}<br />{moment(row.created_at).format('hh:mm:ss')}</small>
    </div>
  },
  { 
    headerFormatter: () => <Label label={"label.points.detail"} />,
    text: 'points.in_detail',
    dataField: 'in_detail', 
    classes: 'text-nowrap',
    formatter: (cell, row) => <small><Label label={"label.point_label." + row.label} /></small>
  },
  { 
    headerFormatter: () => <Label label={"label.points.in"} />,
    text: 'points.in', 
    dataField: 'in_point',  
    classes: 'text-nowrap',
    formatter: (cell, row) => <strong className="text-success">{row.in}</strong>
  },
  { 
    headerFormatter: () => <Label label={"label.points.out"} />,
    text: 'points.out_point', 
    dataField: 'out_point',
    classes: 'text-nowrap',
    formatter: (cell, row) => <strong className="text-danger">{row.out}</strong>
  },
  { 
    headerFormatter: () => <Label label={"label.points.point_detail"} />,
    text: 'points.detail', 
    dataField: 'detail',
    formatter: (cell, row) => <small>
      <LabelHtml
        label={"label.points.order_items_detail"} 
        replaces={{
          'item_name' : row.item_name,
          'order_num' : numeral(row.order_num).format('0,0'),
          'price_in_tax' : numeral(row.price_in_tax).format('0,0'),
          'price_no_tax' : numeral(row.price_no_tax).format('0,0'),
          'price_tax' : numeral(row.price_tax).format('0,0'),
          'total_price_in_tax' : numeral(row.total_price_in_tax).format('0,0'),
          'shipping_fee' : numeral(row.shipping_fee).format('0,0')
        }}
      />
    </small>
  },
];

/** 
 * TABLE
 * 
 * @version 0.1 (2021.06.22 : usayama)
*/
export const BOOTSTRAP_TABLE_COLUMNS_ORDER_ITEMS = [
  { 
    headerFormatter: () => <div><Label label={"label.order_items.created_at"} /></div>,
    text: 'order_items.created_at', 
    dataField: 'created_at', 
    formatter: (cell, row) => <div>
      <small>{moment(row.created_at).format('YYYY-MM-DD')}<br />{moment(row.created_at).format('hh:mm:ss')}</small>
    </div>
  },
  { 
    headerFormatter: () => <div><Label label={"label.order_items.order_number"} /></div>,
    text: 'order_items.order_number', 
    dataField: 'order_number', 
    formatter: (cell, row) => <small>{row.order_number}</small>
  },
  { 
    headerFormatter: () => <Label label={"label.order_items.item_name"} />,
    text: 'order_items.item_name', 
    dataField: 'item_name', 
    formatter: (cell, row) => <div>
      <LabelHtml
        label={"label.order_items.order_items_detail"} 
        replaces={{
          'item_name' : row.item_name,
          'order_num' : numeral(row.order_num).format('0,0'),
          'price_in_tax' : numeral(row.price_in_tax).format('0,0'),
          'price_no_tax' : numeral(row.price_no_tax).format('0,0'),
          'price_tax' : numeral(row.price_tax).format('0,0'),
          'total_price_in_tax' : numeral(row.total_price_in_tax).format('0,0'),
          'shipping_fee' : numeral(row.shipping_fee).format('0,0'),
          'url' : process.env.APP_URL + APP_ROUTE.shops + '/' + row.shop_url  + '/' + row.item_url
        }}
      />
    </div>
  }
];
