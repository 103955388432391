/** 
 * import : 
 * 
 * @version 0.1 (2021.01.26 : usayama)
 * @version 0.2 (2021.05.17 : usayama) // Link 削除, Label 追加
 * @version 0.3 (2021.05.17 : usayama) // icon 追加
 * @version 0.4 (2021.06.02 : usayama) // Button 追加
 * @version 0.5 (2021.06.15 : usayama) // LabelHtml 追加
*/
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { IoMdClose } from "react-icons/io"
// conf, action
// components
import Label from '@components/Typo/Label'
import LabelHtml from '@components/Typo/LabelHtml'
import Button from '@components/UIKit/Button'
// helper
// style, img
// consts

/** 
 * const : 
 * 
 * @version 0.1 (2021.01.26 : usayama)
*/
const Alert = props => {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.01.26 : usayama)
   * @version 0.2 (2021.05.17 : usayama) // status, label 追加, status, color の記述変更
   * @version 0.3 (2021.05.18 : usayama) // mb-2 追加
   * @version 0.4 (2021.06.15 : usayama) // LabelHtml 追加
   */
  const { className,
          icon,
          color,
          label,
          labelHtml,
          heading,
          text,
          closeBt,
          status,
          isOpen, ...attrs } = props
  const classes = classNames(
    "alert",
    color && "alert-" + color,
    status && "alert-" + status,
    className,
  )

  /** 
   * useState : 
   * 
   * @version 0.1 (2021.01.26 : usayama)
   * @version 0.2 (2021.05.17 : usayama) // status, label 追加, status, color の記述変更
   */
  const [ isShow, setIsShow ] = React.useState(isOpen)

  /** 
   * event : 
   * 
   * @version 0.1 (2021.05.17 : usayama)
   */
  const handleOnClose = () => setIsShow(false)

  /** 
   * return : 
   * 
   * @version 0.1 (2021.01.26 : usayama)
   * @version 0.2 (2021.05.17 : usayama) // label 追加
   * @version 0.3 (2021.05.18 : usayama) // icon 追加
   * @version 0.4 (2021.06.01 : usayama) // aria-hidden="true" 削除
   * @version 0.5 (2021.06.15 : usayama) // LabelHtml 追加
   */
  return isShow && 
    <div>
      <div className={classes} {...attrs}>
        {heading && <h4 className="alert-heading">{heading}</h4>}
        {icon}
        {text}
        {label && <p><Label label={label} /></p> }
        {labelHtml && <p><LabelHtml label={labelHtml} /></p> }
        {
          closeBt && <Button className="close" onClick={handleOnClose}><IoMdClose size={18} /></Button>
        }
      </div>
    </div>
}

/** 
 * propTypes
 * 
 * @author k watanabe
 * @version 0.1 (2021.01.26 : usayama)
 * @version 0.2 (2021.05.17 : usayama) // status, label, open 追加
 * @version 0.3 (2021.06.15 : usayama) // LabelHtml 追加
*/
Alert.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string,
  label: PropTypes.string,
  labelHtml: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.node,
  heading: PropTypes.string,
  text: PropTypes.node,
  closeBt: PropTypes.bool,
  isOpen: PropTypes.bool,
}

/** 
 * defaultProps
 * 
 * @author k watanabe
 * @version 0.1 (2021.01.26 : usayama)
 * @version 0.2 (2021.05.17 : usayama) // status, label, open 追加
 * @version 0.3 (2021.06.15 : usayama) // LabelHtml 追加
*/
Alert.defaultProps = {
  className: "",
  status: "",
  label: "",
  labelHtml: "",
  color: "",
  icon: null,
  heading: null,
  text: null,
  closeBt: false,
  isOpen: false,
}

/** 
 * export
 * 
 * @author k watanabe
 * @version 0.1 (2021.01.26 : usayama)
*/
export default Alert
