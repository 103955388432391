/**
 * import
 *
 * @version 0.1 (2021.05.17 : usayama)
 * @version 0.2 (2021.06.21 : usayama) // 実装
 */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ScaleLoader } from 'react-spinners'
// conf, action
import { BOOTSTRAP_TABLE_COLUMNS_POINTS, BOOTSTRAP_TABLE_COLUMNS_ORDER_ITEMS } from '@config/data.tables'
import { app } from '@config/app.config'
import { APP_ROUTE } from '@config/routers'
import pointAction from '@redux/point/actions'
import orderItemAction from '@redux/order_item/actions'
// components
import ReactBootstrapTable from '@components/UIKit/ReactBootstrapTable'
import LinkButton from '@components/UIKit/LinkButton'
import Label from '@components/Typo/Label'
// containers
// helper
// style, img, elm
// const
const { initGetPoints, getPoints } = pointAction
const { initGetOrderItems, getOrderItems } = orderItemAction

/** 
 * const : 
 * 
 * @version 0.1 (2021.06.21 : usayama_dx)
*/
export default function MypageContainer(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.06.21 : usayama_dx)
   */
  // const { ...attrs } = props
  const dispatch = useDispatch()
  const { loadGetPoints, points } = useSelector(state => state.Point)
  const { loadGetOrderItems, order_items } = useSelector(state => state.OrderItem)

  /** 
   * useState : 
   * 
   * @version 0.1 (2021.06.21 : usayama)
  */
  const [initPage, setInitPage] = React.useState(false)

  /** 
   * componentDidMount : https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.06.21 : usayama)
  */
  React.useEffect(() => {
    if(!initPage) {
      dispatch(initGetPoints())
      dispatch(initGetOrderItems())
      dispatch(getPoints())
      dispatch(getOrderItems())
      setInitPage(true)
    }
  }, [initPage, dispatch])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.06.21 : usayama_dx)
   */
  return (
    <div className="container wrap">
      {/* --------------------- ポイント履歴 ------------------------- */}
      <div className="mt-5 mb-4">
        <h2 className="h2 font-weight-bold">
          <Label label="title.my_points" />
        </h2>
      </div>
      { loadGetPoints && <ScaleLoader color={app.loading_color} /> }
      <ReactBootstrapTable data={points} keyField='id' columns={BOOTSTRAP_TABLE_COLUMNS_POINTS} />
      <hr />
      {/* --------------------- 購入履歴 ------------------------- */}
      <div className="mt-5 mb-4">
        <h2 className="h2 font-weight-bold">
          <Label label="title.my_order_items" />
        </h2>
      </div>
      { loadGetOrderItems && <ScaleLoader color={app.loading_color} /> }
      <ReactBootstrapTable data={order_items} keyField='id' columns={BOOTSTRAP_TABLE_COLUMNS_ORDER_ITEMS} />
      <hr />
      {/* --------------------- ログアウト ------------------------- */}
      <div className="text-center p-4"><LinkButton to={APP_ROUTE.logout} color="outline-success" label="button.logout" /></div>
    </div>
  )
}
