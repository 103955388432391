/**
 * import
 *
 * @version 0.1 (2021.05.17 : usayama)
 * @version 0.2 (2021.06.30 : usayama) // classNames コメントアウト
 */
import React from 'react'
import { navigate } from "gatsby"
import { useDispatch, useSelector } from 'react-redux'
// import classNames from "classnames"
import { IoMailOutline, IoKey } from "react-icons/io5"
import { useForm } from 'react-hook-form'
// conf, action
import { APP_ROUTE } from '@config/routers'
import { VALIDATION_PATTERNS } from '@config/validation.patterns'
import authActions from '@redux/auth/actions'
// components
import ModalLoading from '@components/UIKit/ModalLoading'
import InputGroup from '@components/Form/InputGroup'
import Alert from '@components/UIKit/Alert'
import Button from '@components/UIKit/Button'
import LinkButton from '@components/UIKit/LinkButton'
// containers
import AuthContainer from '@containers/AuthContainer'
// helper
// style, img, elm
// const
const { initLogin, login, getMe } = authActions

/** 
 * const : 
 * 
 * @version 0.1 (2021.06.21 : usayama_dx)
*/
export default function LoginFormContainer(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.06.21 : usayama_dx)
   * @version 0.1 (2021.06.30 : usayama_dx) // default value 削除
   */
  // const { ...attrs } = props
  const { handleSubmit, 
          register, 
          formState, 
          setError,
          errors } = useForm({ mode: 'onChange' })
  // const { className } = props
  // const classes = classNames( className )
  const dispatch = useDispatch()
  const { loadLogin, loginResult, loginResultStatus } = useSelector(state => state.Auth)

  /** 
   * useState : 
   * 
   * @version 0.1 (2021.06.21 : usayama_dx)
   */
  const [ initPage, setInitPage ] = React.useState(false)

  /** 
   * event : 
   * 
   * @version 0.1 (2021.06.21 : usayama)
   * @version 0.1 (2021.06.23 : usayama) // initLogin 削除
   */
  const handleOnSubmit = (d) => dispatch(login(d))

  /** 
   * useEffect
   * 
   * @version 0.1 (2021.06.21 : usayama)
   */
  React.useEffect(() => {
    if(loginResult && loginResult.errors) {
      Object.keys(loginResult.errors).map((key) => setError(key, { type: "manual", message: loginResult.errors[key] }))
    }
  }, [loginResult, setError])

  /** 
   * useEffect
   * 
   * @version 0.1 (2021.06.21 : usayama)
   * @version 0.2 (2021.06.23 : usayama) // getMe 追加, mypage ではなく root にリダイレクト
  */
  React.useEffect(() => {
    if(loginResultStatus === 'success') {
      dispatch(getMe())
      navigate(APP_ROUTE.root)
    }
  }, [loginResultStatus, dispatch])

  /** 
   * componentDidMount : https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.06.21 : usayama)
   * @version 0.1 (2021.06.23 : usayama) // initLogin 追加
   */
  React.useEffect(() => {
    if(!initPage) {
      dispatch(initLogin())
      setInitPage(true)
    }
  }, [initPage, dispatch])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.06.21 : usayama_dx)
   * @version 0.2 (2021.06.30 : usayama_dx) // className={classes} 削除
   */
  return (
    <AuthContainer titleLabel="title.login" textLabel="text.login">
      <ModalLoading isLoading={loadLogin} />
      { loginResultStatus && <Alert status={loginResultStatus} label={"message." + loginResultStatus + ".login"} isOpen={true} closeBt={true} /> }
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <InputGroup
          label={<IoMailOutline size={25} />}
          placeholder="placeholder.login_email"
          type="email"
          name="email"
          feedback={errors && errors.email}
          errors={errors && errors.email}
          className={errors.email && "is-invalid"}
          inputClassName={errors.email && "is-invalid"}
          inputRef={register({ required: true, pattern: VALIDATION_PATTERNS.email })}
        />
        <InputGroup
          label={<IoKey size={25} />}
          placeholder="placeholder.password"
          type="password"
          name="password"
          feedback={errors && errors.password}
          errors={errors && errors.password}
          className={errors.password && "is-invalid"}
          inputClassName={errors.password && "is-invalid"}
          maxLength={255}
          inputRef={register({ required: true, pattern: VALIDATION_PATTERNS.password })}
        />
        <div className="form-action">
          <Button type="submit" color="success" label="login" disabled={!formState.isValid} />
        </div>
      </form>
      <hr />
      <LinkButton to={APP_ROUTE.signup} label="text.to_signup" className="mb-0" />
      <LinkButton to={APP_ROUTE.forgot_password} label="text.to_forgot_password" />
    </AuthContainer>
  )
}
