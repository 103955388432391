/**
 * import
 *
 * @version 0.1 (2021.05.13 : usayama)
 * @version 0.2 (2021.06.21 : usayama) // LoginFormContainer に変更, MypageContainer 追加
 * @version 0.3 (2021.06.28 : usayama) // Seo 追加
 * @version 0.4 (2021.07.01 : usayama) // ScaleLoader 追加
 */
import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ScaleLoader } from 'react-spinners'
// conf, action
import { app } from '@config/app.config'
// components
import Seo from '@components/Tools/Seo'
// containers
import LayoutContainer from '@containers/LayoutContainer'
import LoginFormContainer from '@containers/LoginFormContainer'
import MypageContainer from '@containers/MypageContainer'
// helper
import { isEmpty } from '@lib/helpers'
// style, img, elm
// const

/**
 * const
 *
 * @version 0.1 (2021.05.13, 05.17 : usayama)
 * @version 0.2 (2021.06.19 : usayama) // 実装
 */
export default function Mypage(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.06.17 : usayama)
   * @version 0.2 (2021.06.21 : usayama) // 実装
   * @version 0.3 (2021.06.28 : usayama) // useTranslation 追加
   */
  const { t } = useTranslation()
  const { me } = useSelector(state => state.Auth)

  /** 
   * state : 
   * 
   * @version 0.1 (2021.06.17 : usayama)
  */
  const [initPage, setInitPage] = React.useState(false)

  /** 
   * componentDidMount
   * https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.06.17 : usayama)
   * @version 0.2 (2021.06.21 : usayama) // 実装
   */
  React.useEffect(() => {
    if(!initPage) {
      setInitPage(true)
    }
  }, [initPage])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.06.21 : usayama)
   * @version 0.2 (2021.06.28 : usayama) // Seo 追加
   * @version 0.3 (2021.07.01 : usayama) // ScaleLoader 追加, 表示判定処理変更
   */
  return (
    <LayoutContainer>
      <Seo title={t('title.mypage')} />
      { me === null && <div className="m-5 text-center"><ScaleLoader color={app.loading_color} /></div> }
      { me !== null && (!isEmpty(me) ? <MypageContainer /> : <LoginFormContainer />) }
    </LayoutContainer>
  )
}
