/** 
 * import : 
 * 
 * @version 0.1 (2021.02.22)
 * @version 0.2 (2021.05.17) // useTranslation 追加, Label 削除, Link 削除
*/
import React from 'react'
import PropTypes from "prop-types"
import classNames from "classnames"
import { useTranslation } from 'react-i18next'
// conf, action
// components
// helper
// style, img
// consts

/** 
 * const : 
 * 
 * @version 0.1 (2021.02.22)
*/
const InputGroup = props => {

  /** 
   * const : 
   * 
     * @version 0.1 (2021.02.22)
   * @version 0.2 (2021.03.05) // labelTextClassName, inputRef 追加
   * @version 0.3 (2021.05.17) // useTranslation,  inputClassName, errors, inputGroupClasses 追加
   */
  const { t } = useTranslation()
  const { className,
          inputClassName,
          labelTextClassName,
          size,
          type,
          id,
          label,
          name,
          inputRef,
          position,
          placeholder,
          helpText,
          feedback,
          errors,
          readonly,
          disabled, ...attrs } = props
  const classes = classNames(
    "mb-3",
    className,
  )
  const inputGroupClasses = classNames(
    "input-group",
    size && "input-group-" + size,
  )
  const inputClasses = classNames(
    "form-control",
    size && "form-control-" + size,
    inputClassName,
  )
  const labelClasses = classNames(
    "input-group-" + position,
  )
  const labelTextClasses = classNames(
    "input-group-text",
    labelTextClassName,
  )

  /** 
   * return : 
   * 
   * @version 0.1 (2021.02.22)
   * @version 0.2 (2021.03.05) // labelTextClassName , ref, attrs 追加
   * @version 0.3 (2021.05.17) // Label を useTranslation に変更, errors 追加, 構造組み換え
   */
  return (
    <div className={classes}>
      <div className={inputGroupClasses}>
        { ( position === 'prepend' && label ) && <div className={labelClasses}><div className={labelTextClasses}>{label}</div></div> }
        <input 
          type={type} 
          name={name} 
          className={inputClasses} 
          id={id} 
          placeholder={t(placeholder)} 
          disabled={disabled}
          ref={inputRef}
          {...attrs}
        />
        { ( position === 'append' && label ) && <div className={labelClasses}><div className={labelTextClasses}>{label}</div></div> }
      </div>
      { helpText && <small className="form-text text-muted">{t(helpText)}</small> }
      { errors && <div className="form-text text-danger">{t('message.error.' + name)}</div> }
      { feedback && <div className={feedback.status + "-feedback"}>{t(feedback.text)}</div> }
    </div>
  )
}

/** 
 * propTypes
 * 
 * @version 0.1 (2021.02.22)
 * @version 0.2 (2021.03.05) // labelTextClassName, inputRef 追加
 * @version 0.3 (2021.05.17) // inputClassName, errors 追加
*/
InputGroup.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  labelTextClassName: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.node,
  position: PropTypes.string,
  placeholder: PropTypes.string,
  helpText: PropTypes.string,
  errors: PropTypes.object,
  feedback: PropTypes.object,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  inputRef: PropTypes.func,
}

/** 
 * defaultProps
 * 
 * @version 0.1 (2021.02.22)
 * @version 0.2 (2021.03.05) // labelTextClassName, inputRef 追加
 * @version 0.3 (2021.05.17) // inputClassName, errors 追加
*/
InputGroup.defaultProps = {
  className: "",
  inputClassName: "",
  labelTextClassName: "",
  type:"text",
  size: null,
  id: null,
  name: "",
  position: 'prepend', // or append
  label: null,
  options: null,
  placeholder: null,
  helpText: null,
  errors: null,
  feedback: null,
  readonly: false,
  disabled: false,
  inputRef: null,
}

/** 
 * export
 * 
 * @version 0.1 (2021.02.22)
*/
export default InputGroup
