/** 
 * import : 
 * 
 * @author k watanabe
 * @since 0.1 (latest update : 2021.03.23)
*/
import React from 'react'
import PropTypes from "prop-types"
// import classNames from "classnames"
import { ScaleLoader } from "react-spinners"
// conf, action
// components
// helper
// style, img
// consts

/** 
 * const : 
 * 
 * @author k watanabe
 * @since 0.1 (latest update : 2021.03.23)
*/
const ModalLoading = props => {

  /** 
   * const : 
   * 
   * @author k watanabe
   * @since 0.1 (latest update : 2021.03.23)
   */
  const { isLoading, ...attrs } = props

  /** 
   * return : 
   * 
   * @author k watanabe
   * @since 0.1 (latest update : 2021.03.23)
   */
  return isLoading && 
            <div 
              style={{'position': 'fixed','left': 0,'top': 0,'zIndex': 9999, 'backgroundColor': 'rgba(0, 0, 0, 0.8)',}} 
              className="w-100 h-100 d-flex justify-content-center align-items-center"
              {...attrs}
            >
              <ScaleLoader color="#fff" />
            </div>
}

/** 
 * propTypes
 * 
 * @author k watanabe
 * @since 0.1 (latest update : 2021.03.23)
*/
ModalLoading.propTypes = {
  isLoading: PropTypes.bool,
}

/** 
 * defaultProps
 * 
 * @author k watanabe
 * @since 0.1 (latest update : 2021.03.23)
*/
ModalLoading.defaultProps = {
  isLoading: false,
}

/** 
 * export
 * 
 * @author k watanabe
 * @since 0.1 (latest update : 2021.03.23)
*/
export default ModalLoading
